import React from 'react';

import Layout from '../components/Layout';
import pic1 from '../assets/images/historic4.jpg';
import pic2 from '../assets/images/castle2.jpg';
import pic4 from '../assets/images/history3.jpg';
import pic5 from '../assets/images/about3.jpg';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>About Us</h2>
          <p>Who are we?</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h2 className="major">OUR GOAL</h2>
          <p>
            <b>Transylvania Meat Co.</b> was created with one simple purpose in mind. We aspire to share Romania's delicious meat dishes with the world, 
			and allow people to finally experience what traditional Romanian cuisine has to offer. 
          </p><span className="image left"><img src={pic1} alt="" /></span>

          <p>
		  Originally, we wanted to allow Romanians living in Chicago the chance to enjoy the traditional foods they had been missing from home. 
		  However, it quickly became clear that our meat was growing in popularity with residents from all over the Chicagoland area. 
		  We realized that we had a momentous opportunity to spread Romania’s delicious meat recipes to people of all different nationalities. 
		  This is the reason Transylvania Meat Co. puts such passion and commitment into ensuring our meat is of the utmost quality. 
		  First impressions count, and we know that for many, our meat is their initial sample of Romanian cuisine. 
		  Our goal is to astonish and amaze our customers with the incredible flavor that Romanian meat has to offer.
		  </p>
		  <p> 
		  As of today, we continue to serve our meat at locations throughout Chicagoland, Downers Grove, Des Plains, Niles, and Arlington Heights. 
		  Eventually, we plan to supply our exceptional meat throughout the state of Illinois, and hopefully the rest of the United States.  
          </p>
          </div>
          </div>
          </section>

          <br></br>

          <section className="wrapper alt sausage" id="history">
      <div className="inner">
          <div>
          
          <h2 className="major"><span className="icon2 fa-university"></span>HISTORY</h2>
          <p>
          Despite being a relatively “young” country, Romania’s roots can be traced back to the oldest civilizations known to man. 
          Modern Romania was founded in 1859, when Moldavia and Wallachia joined together through a personal union of the Danubian Principalities of Moldavia and Wallachia. 
          However, Romania’s heritage dates back to approximately 40,000 years ago. 
          In fact, the oldest human bones ever discovered in Europe happened to be unearthed in Romania. 
          </p>
          <p>
          <span className="image right"><img src={pic2} alt="" /></span>
          Ancient Greece was the first well established civilization to reach the shores of what is now Romania. 
          The Greeks established colonies on the coast of the Black Sea in 700 BC but were eventually conquered by the Dacians in 55 BC. 
          The land was then conquered by the Romans in 106 AD, but Goths and other neighboring tribes forced the Romans to abandon most of the land around 271 AD. 
          However, parts of what is modern-day Romania remained integral to the Roman Empire until the early 7th century. 
          The list of different civilizations that established their presence in Romania is almost too many to count. 
          The Huns, the Byzantines, the Hungarian Empire, and even the Ottoman Empire controlled the territory at various points of its elaborate history. 
          </p>
          
          <p>
          Now, how is this relevant to Transylvania Meat Co? 
          Well, food is usually considered a vital part of every nation’s culture. 
          Most cultures we see today are the combination of different philosophies and traditions that have been molded together over time. 
          This is especially true with a nation’s style of cooking. 
          A nation’s cuisine is formed over time, blending together different recipes and methods based on the societies that have had an impact on its history. 
          In this case, Romanian cuisine was influenced by an incredible number of different cultures. It melded a plethora of differing cooking styles together. 
          Among the more well-known are the Greek, Roman, Turkish, Austrian, and Belgian cuisines. 
          Although it is less known around the world, Romania’s rich cuisine always delivers a delightful surprise to tourists. 
          A Romanian dish may look familiar at first glance, but it always has a unique taste, 
          aided by the vast number of different cultures that helped contribute to its makeup. 
          </p>
          <p>
          At Transylvania Meat Co., it is our mission to enthusiastically reveal this often-underestimated cuisine to our patrons. 
          With that ambitious goal in mind, we always ensure that our meat is of the highest quality and guarantee it will leave our customers astounded. 
          We consider it a privilege to present our delicious Romanian heritage to the world.
          </p>                 
        </div>
      </div>
    </section>


    <br></br>
    
    <section className="wrapper" id="locations">
      <div className="inner">
      
         
                 
         
         
          <h2 className="major"><span className="icon2 fa-compass fa-lg"></span>LOCATIONS</h2>
          <p>Find our products at the following stores:</p>

          <div className="">  
     {/* <iframe className="box alt2" src="https://www.google.com/maps/d/embed?mid=17oWT_eBO_EolG3NoayVpgriDsY5rJADt&hl=en"  width="100%" height="700em" allowfullscreen="" loading="lazy"></iframe> */}
      </div>  
     
     
      
      



{/* <div className="row">

<h2 className="col-6 ">FRESH FARMS INTERNATIONAL</h2>
<h2 className="col-6 ">SHOP & SAVE MARKET</h2>

</div>
<div className="row">

<div className="col-6">
<p>
    <h3><a href="https://g.page/FreshFarmsTouhy?share" target="_blank"><u>5740 W Touhy Ave, Niles, IL 60714</u></a></h3>
    </p>
    
   

    
   
    <p> 
    <h3><a href="https://g.page/FreshFarmsGolf?share" target="_blank"><u>8203 W Golf Rd, Niles, IL 60714</u></a></h3>            
    </p>
    


    <p>
    <h3><a href="https://g.page/FreshFarmsWheeling?share" target="_blank"><u>20 S Milwaukee Ave, Wheeling, IL 60090</u></a></h3>
    </p>
    </div>




   




<div className="col-6">
<p>
    <h3><a href="https://g.page/shopandsavemarketdesplaines?share" target="_blank"><u>518 Metropolitan Way, Des Plaines, IL 60016</u></a></h3>
    </p>
    
 

  
    <p> 
    <h3><a href="https://g.page/shopandsavemarketdownersgrove?share" target="_blank"><u>7241 Lemont Rd, Downers Grove, IL 60516</u></a></h3>            
    </p>
   

    
    <p>
    <h3><a href="https://g.page/shopandsavemarketnagle?share" target="_blank"><u>6312 N Nagle Ave, Chicago, IL 60646</u></a></h3>
    </p>
   


    
    <p>
    <h3><a href="https://g.page/shopandsavemarketchicago?share" target="_blank"><u>5829 S Archer Ave, Chicago, IL 60638</u></a></h3>
    </p>



    </div>

  </div>
*/}
</div>

</section>

<section className="wrapper alt sausage" id="">
<div className="inner">

{/*
<section className="wrapper alt sausage" id="">
<div className="inner">

    <div className="row">
    <h2 className="col-6  ">KRYSTYNN'S DELI</h2>

    <h2 className="col-6 ">LEWIS FRESH MARKET</h2>

    </div>
   
<div className="row">

    <div className="col-6">

<p>
    <h3><a href="https://goo.gl/maps/jvvMMNccuRXuzr1F7" target="_blank"><u>1046 Elmhurst Rd, Mt Prospect, IL 60056</u></a></h3>
    </p>
<p>


 <h3><a href="https://goo.gl/maps/NgKoq6jdZ2ZzjTc8A" target="_blank"><u>1102 S Roselle Rd, Schaumburg, IL 60193</u></a></h3>
  </p>

  </div>
    
 


<div className="col-6">
<p>
    <h3><a href="https://goo.gl/maps/Tjqv16tAfBA1Rg2m8" target="_blank"><u>2727 Grand Ave, Waukegan, IL 60085</u></a></h3>
    </p>


</div>
</div>
</div>
</section>


<section className="wrapper" id="">
<div className="inner">
    <div className="row">
    <h2 className=" col-6">HARVEST FRESH MARKET</h2>
    <h2 className=" col-6 ">JERRY'S FRUIT AND GARDEN</h2>
    </div>
<div className="row">
<div className="col-6">
<p>
    <h3><a href="https://goo.gl/maps/5ASSYpV7usC83h5AA" target="_blank"><u>100 E Rand Rd, Arlington Heights, IL 60004</u></a></h3>
    </p>

</div>


<div className="col-6">

<p>
    <h3><a href="https://goo.gl/maps/zuFmi2dpmxf1QHsB7" target="_blank"><u>7901 N Milwaukee Ave, Niles, IL 60714</u></a></h3>
    </p>

</div>    
</div>
</div>
</section>

<section className="wrapper alt sausage" id="">
<div className="inner">

<div className="row">
<h2 className="col-6 ">MARKET PLACE ON OAKTON</h2>
<h2 className="col-6 ">MONTROSE MARKET</h2>

</div>
<div className="row">
<div className="col-6">

<p>
    <h3><a href="https://g.page/marketplaceonoakton?share" target="_blank"><u>4817 Oakton St, Skokie, IL 60077</u></a></h3>
    </p>

</div>
    

<div className="col-6">

<p>
    <h3><a href="https://goo.gl/maps/tTSLFT98bKdRNBBM9" target="_blank"><u>1731 W Golf Rd, Mt Prospect, IL 60056</u></a></h3>
    </p>


  </div>

  </div>
</div>
</section>


  
  <section className="wrapper" id="">
<div className="inner">

<div className="row ">
<h2 className=" col-6">CITY FRESH MARKET</h2>
<h2 className=" col-6">VALLI PRODUCE</h2>
</div>

<div className="row">
<div className="col-6">

<p>
    <h3><a href="https://goo.gl/maps/8R6EQ6uXNLr8dgLr7" target="_blank"><u>3201 W Devon Ave, Chicago, IL 60659</u></a></h3>
    </p>

</div>    



<div className="col-6">

<p>
    <h3><a href="https://goo.gl/maps/CDhQispSXXnK2B6n9" target="_blank"><u>155 E North Ave, Glendale Heights, IL 60139</u></a></h3>
    </p>

</div>    


</div>

   </div>
    </section>
*/}
    



         
         

          <div className="">

          

          <h2 className="major">FRESH FARMS INTERNATIONAL</h2>
          <div className="row">
          
          <div className="col-6">
          <p>
              <h3><u>5740 W Touhy Ave, Niles, IL 60714</u></h3>
               <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380898.1269375428!2d-88.13822712683385!3d41.76868939107116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fced15568a5db%3A0x557d24f60d97da5!2sFresh%20Farms%20International%20Market!5e0!3m2!1sen!2sus!4v1647298707655!5m2!1sen!2sus"  width="100%" height="100%" allowfullscreen="" loading="lazy"></iframe>
              </p>
              </div>
             

              
              <div className="col-6">
              <p> 
              <h3><u>8203 W Golf Rd, Niles, IL 60714</u></h3>            
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2962.487420514607!2d-87.83385538425846!3d42.05417066235574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fc8744074bdf3%3A0xe9a3976c8c32ed90!2sFresh%20Farms%20International%20Market!5e0!3m2!1sen!2sus!4v1619841254414!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>
              </div>

            <div className="col-6">
              <p>
              <h3><u>20 S Milwaukee Ave, Wheeling, IL 60090</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2958.528325672764!2d-87.9105343842566!3d42.13897985702633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fbecb5764b405%3A0xb4645d77a3cedbea!2sFresh%20Farms%20International%20Market!5e0!3m2!1sen!2sus!4v1619841554515!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>
              </div>


              </div>

              </div>


              <div className="">


              <h2 className="major">BROOKHAVEN MARKETPLACE</h2>
          <div className="row">
          <div className="col-6">
          <p>
              <h3><u>100 Burr Ridge Pkwy, Burr Ridge, IL 60527</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190742.51575706847!2d-88.13900291967455!3d41.669744904159096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4898661b2bd3%3A0x95eb9ebef81490dd!2sBrookhaven%20Marketplace!5e0!3m2!1sen!2sus!4v1653603388440!5m2!1sen!2sus" width="100%" height="100%" allowfullscreen="" loading="lazy"></iframe>
              </p>
              
             </div>

             <div className="col-6">
              <p> 
              <h3><u>7516 S Cass Ave #30, Darien, IL 60561</u></h3>            
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2976.6545883540234!2d-87.9772656488609!3d41.74954268132633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4f8252e4090d%3A0x42c7b394a9764d1a!2sBrookhaven%20Marketplace!5e0!3m2!1sen!2sus!4v1653603643434!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>
              </div>

              <div className="col-6">
              <p>
              <h3><u>19818 South La Grange Road, Mokena, IL 60448</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2986.8098946478945!2d-87.85433704886627!3d41.53005839499923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e14cdde19f40f%3A0x44544c0572a0eb29!2sBrookhaven%20Marketplace!5e0!3m2!1sen!2sus!4v1653603757629!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>
              </div>


             

              </div>


                  {/*
          <h2 className="major">SHOP & SAVE MARKET</h2>
          <div className="row">
          <div className="col-6">
          <p>
              <h3><u>518 Metropolitan Way, Des Plaines, IL 60016</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2963.009899355583!2d-87.88771078425874!3d42.042968063059035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fb799f04eac19%3A0xc6ce051bc33a0847!2sShop%20%26%20Save%20Market!5e0!3m2!1sen!2sus!4v1619845376283!5m2!1sen!2sus" width="100%" height="100%" allowfullscreen="" loading="lazy"></iframe>
              </p>
              
             </div>

             <div className="col-6">
              <p> 
              <h3><u>7241 Lemont Rd, Downers Grove, IL 60516</u></h3>            
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2976.4009990319905!2d-88.01173668426505!3d41.755011381086454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e501d63cececb%3A0x706d87b8a4689a3a!2sShop%20%26%20Save%20Market!5e0!3m2!1sen!2sus!4v1619845492538!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>
              </div>

              <div className="col-6">
              <p>
              <h3><u>6312 N Nagle Ave, Chicago, IL 60646</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.189695919113!2d-87.79072598425977!3d41.99620426599342!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fc947097c6177%3A0x26a6a70406603974!2sShop%20%26%20Save%20Market!5e0!3m2!1sen!2sus!4v1619845604283!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>
              </div>


              <div className="col-6">
              <p>
              <h3><u>5829 S Archer Ave, Chicago, IL 60638</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2974.514860149607!2d-87.76177418426413!3d41.79566797854727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e313f0297a727%3A0x99a313ab4d70a7e1!2sShop%20%26%20Save%20Market!5e0!3m2!1sen!2sus!4v1619845702321!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>


              </div>


              </div>


              */}
              </div> 
              <h2 className="major">LEWIS FRESH MARKET</h2>
          <div>
          <p>
              <h3><u>2727 Grand Ave, Waukegan, IL 60085</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2947.7139567275813!2d-87.87159368425145!3d42.36993494247003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f92a6aff6aac9%3A0x5f98cdf3b538546b!2sLewis%20Fresh%20Market!5e0!3m2!1sen!2sus!4v1619846187803!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>

          </div>
                

              <h2 className="major">HARVEST FRESH MARKET</h2>
          <div>
          <div className="col-6">
          <p>
              <h3><u>100 E Rand Rd, Arlington Heights, IL 60004</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2959.699229988394!2d-87.98346058425712!3d42.113911958602564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fbb0e465dcd07%3A0xe78182553d97197d!2sHarvest%20Fresh%20Market!5e0!3m2!1sen!2sus!4v1619845996902!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>

          </div>
          </div>

          


          <h2 className="major">MARKET PLACE ON OAKTON</h2>
          <div className="col-6">

          <p>
              <h3><u>4817 Oakton St, Skokie, IL 60077</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2963.855743137167!2d-87.7504126842591!3d42.02482696419775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fcf1a9c430bd9%3A0x94c2d8cf6d47d53f!2sMarket%20Place%20On%20Oakton!5e0!3m2!1sen!2sus!4v1619846320234!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>

          </div>
              
          <h2 className="major">MONTROSE MARKET</h2>
          <div className="col-6">

          <p>
              <h3><u>1731 W Golf Rd, Mt Prospect, IL 60056</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380399.00165606354!2d-88.24190112885731!3d41.85268512961608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fb1966dc75cef%3A0x58c292b259e01169!2sMontrose%20Market!5e0!3m2!1sen!2sus!4v1627074551637!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>

          </div>

          <h2 className="major">KRYSTYNN'S DELI</h2>
          <div className="row">
          <div className="col-6">

          <p>
              <h3><u>1046 Elmhurst Rd, Mt Prospect, IL 60056</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380383.1866382892!2d-88.24190112733724!3d41.85534432613534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fb0b0c75a5d67%3A0xf055cb83659502a2!2sKD%20Market-%20Krystyna&#39;s%20Deli!5e0!3m2!1sen!2sus!4v1627074709789!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>

          </div>

          <div className="col-6">

    <p>
           <h3><u>1102 S Roselle Rd, Schaumburg, IL 60193</u></h3>
            <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190262.75167102666!2d-88.13197194779384!3d41.83140969949761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880faecf2d92ea39%3A0x364b714e9ba30ad4!2sKD%20Market-Krystyna&#39;s%20Deli!5e0!3m2!1sen!2sus!4v1627074894500!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
            </p>

            </div>
            </div>
            <h2 className="major">JERRY'S FRUIT AND GARDEN</h2>
          <div className="col-6">

          <p>
              <h3><u>7901 N Milwaukee Ave, Niles, IL 60714</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190235.70253462667!2d-88.07456423014658!3d41.840509187851346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fc8f9101c804b%3A0x5629d4ec04e9c3e5!2sJerry&#39;s%20Fruit%20%26%20Garden!5e0!3m2!1sen!2sus!4v1627075133693!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>

          </div>    


          <h2 className="major">CITY FRESH MARKET</h2>
          <div className="col-6">

          <p>
              <h3><u>3201 W Devon Ave, Chicago, IL 60659</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190278.87454695842!2d-88.02314039852911!3d41.82598510128783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fce3d9fb92a03%3A0x2967d356a4a4db62!2sCity%20Fresh%20Market!5e0!3m2!1sen!2sus!4v1627075257420!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>

          </div>    


          <h2 className="major">VALLI PRODUCE</h2>
          <div className="row">
          
          
          <div className="col-6">

          <p>
              <h3><u>850 N Roselle Rd, Hoffman Estates, IL 60169</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380473.29575599387!2d-88.27172658599812!3d41.84019119600915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880faf47db9edeef%3A0x254b54390d730fe6!2sValli%20Produce!5e0!3m2!1sen!2sus!4v1647480804224!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>

          </div>    

          <div className="col-6">

            <p>
                <h3><u>5880 E State St, Rockford, IL 61108</u></h3>
                <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d759371.1519906445!2d-89.07329280858893!3d41.97250652610183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8808b82555555555%3A0xdb22f4944aa0dc8f!2sValli%20Produce!5e0!3m2!1sen!2sus!4v1651015590459!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
                 </p>

        </div>    

        <div className="col-6">

        <p>
            <h3><u>1910 Dempster St, Evanston, IL 60202</u></h3>
                <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2963.2092841644812!2d-87.70258904885374!3d42.038692363225756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fcfe109061d3d%3A0xb759847aa197a6b3!2sValli%20Produce!5e0!3m2!1sen!2sus!4v1651015833523!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
            </p>

           
            </div>

            <div className="col-6">

            <p>
                <h3><u>6550 N Alpine Rd, Loves Park, IL 61111</u></h3>
                <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.0257774371103!2d-89.02947904884672!3d42.32064894547979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8808bc05552ab1c7%3A0xf8d8b0ae1690109b!2sValli%20Produce!5e0!3m2!1sen!2sus!4v1651016050585!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
                 </p>

                    </div> 
        </div>


                    <h2 className="major">NAPERVILLE FRESH MARKET</h2>
                 <div className="row">
                    <div className="col-12">

            <p>
                <h3><u>955 W 75th St, Naperville, IL 60565</u></h3>
                <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2976.646274456163!2d-88.16697024886086!3d41.74972198131508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e57fe936eb82f%3A0x1d05d159280fc16c!2sNaperville%20Fresh%20Market!5e0!3m2!1sen!2sus!4v1651016269886!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
                 </p>

                </div>         

                </div>
                



              {/*
          
          <p>
              <h3><u>5740 W Touhy Ave, Niles, IL 60714</u></h3>

              <div className="col-6">

            <p>
                <h3><u>5880 E State St, Rockford, IL 61108</u></h3>
                <iframe className="box alt2" src="" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
                 </p>

                </div>      
             
             
             
              */}
          
    
        
        <h2 className="major">DELI 4 YOU MARKET</h2>
          <div className="row">
          <div className="col-6">

          <p>
              <h3><u>9 E Camp McDonald Rd, Prospect Heights, IL 60070</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380219.4698639296!2d-88.24127676160204!3d41.882864190285794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fba2a36a91a37%3A0x695b5cbe4d85cc37!2sDeli%204%20You%20Market!5e0!3m2!1sen!2sus!4v1667060680828!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>

            </div>

            <div className="col-6">

            <p>
              <h3><u>560 S Roselle Rd, Schaumburg, IL 60193</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2964.208799230599!2d-88.08405198425926!3d42.017252964672885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880faed0c9abc5dd%3A0xd04fd8f8d163c6ae!2sDeli%204%20You%20Market!5e0!3m2!1sen!2sus!4v1667061012544!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
              </p>

                </div>   

                <div className="col-6">
                <p>
                 <h3><u> 1601 S Randall Rd, Algonquin, IL 60102  </u></h3>
                 <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2957.8783454951!2d-88.33449668425628!3d42.152890056151485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f13f1ca0f9377%3A0xf03b1ef0f42a2914!2sDeli%204%20You%20Market!5e0!3m2!1sen!2sus!4v1667061151439!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
                 </p>


                </div>

                <div className="col-6">
                <p>
                 <h3><u> 8526 W Golf Rd, Niles, IL 60714 </u></h3>
                 <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380329.3584705595!2d-88.20220832216363!3d41.86439416431164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fc952e3169957%3A0x70c24cf408e89ef4!2sDeli%204%20You%20Market!5e0!3m2!1sen!2sus!4v1678316045098!5m2!1sen!2su" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
                 </p>

                </div>

                </div>


            <h2 className="major">TANNOUR SHAWARMA & BAKERY</h2>

            <div className="row">

             <div className="col-6">

          <p>
                 <h3><u> 6699 Lincoln Ave, Lincolnwood, IL 60712  </u></h3>
                 <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2964.8809915067186!2d-87.72924048425962!3d42.002829565577876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fcf215b9fda2d%3A0x7a2aebe792432acc!2sTannour%20Shawarma%20%26%20Bakery!5e0!3m2!1sen!2sus!4v1667061416938!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
                 </p>

            </div>
            </div>

            <h2 className="major">EURO DELI</h2>

<div className="row">

 <div className="col-6">

<p>
     <h3><u> 670 N Coit Rd Ste #2360, Richardson, TX 75080  </u></h3>
     <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509711.669055453!2d-96.87628134563397!3d37.1866851171417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c21c732832657%3A0xa498055dfb55ea98!2sEuro%20Deli!5e0!3m2!1sen!2sus!4v1670096196925!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
     </p>

</div>
</div>

<h2 className="major">PARTHENON FOODS - EUROPEAN MARKET</h2>

            <div className="row">

             <div className="col-6">

          <p>
                 <h3><u> 8415 W Greenfield Ave, West Allis, WI 53214 </u></h3>
                 <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d754824.7793689697!2d-88.39845059619181!3d42.352451383879384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8805054eba1503ef%3A0xc3403d5b20d87ab1!2sParthenon%20Foods%20-%20European%20Market!5e0!3m2!1sen!2sus!4v1670096434382!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
                 </p>

            </div>
            </div>

            <h2 className="major">VILLAGE MARKET PLACE INC</h2>

<div className="row">

 <div className="col-6">

<p>
     <h3><u> 4034 Dempster St, Skokie, IL 60076 </u></h3>
     <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380355.1628078889!2d-88.10039847464375!3d41.86005601997522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fcf997f7db557%3A0x4ccc00369caba741!2sVillage%20Market%20Place%20Inc!5e0!3m2!1sen!2sus!4v1670096602230!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
     </p>

</div>
</div>

<h2 className="major">TASTE OF POLAND EUROPEAN TAVERN</h2>

<div className="row">

 <div className="col-6">

<p>
     <h3><u> 2301 Central Expy #155, Plano, TX 75075 </u></h3>
     <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7781368.69194037!2d-98.52315636752567!3d36.79495968788634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c18fe54bedecd%3A0x72a2d771c47a4aa8!2sTaste%20of%20Poland%20European%20Tavern!5e0!3m2!1sen!2sus!4v1670096762213!5m2!1sen!2sus" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>
     </p>

</div>
</div>


<h2 className="major">CAPE CORAL EUROPEAN MARKET</h2>
          <div className="row">
          <div className="col-6">
          <p>
              <h3><u>4536 SE 16th Pl Unit #3, Cape Coral, FL 33904</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13581618.925028892!2d-94.45751912171642!3d33.79101876637089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88db3ff02105f0e1%3A0x4ccd632d47c472f1!2sCape%20Coral%20European%20Market!5e0!3m2!1sen!2sus!4v1678316461201!5m2!1sen!2sus" width="100%" height="100%" allowfullscreen="" loading="lazy"></iframe>
              </p>
              
             </div>

            

             

              </div>




            <h2 className="major">EUROPA GOURMET</h2>
          <div className="row">
          <div className="col-6">
          <p>
              <h3><u>1422 S Federal Hwy, Hollywood, FL 33020</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3586.0478982052246!2d-80.14541418454085!3d25.999248305071806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9ab82abd9554f%3A0x282da95ea00f1763!2sEuropa%20Gourmet!5e0!3m2!1sen!2sus!4v1678317194659!5m2!1sen!2sus" width="100%" height="100%" allowfullscreen="" loading="lazy"></iframe>
              </p>
              
             </div>

            

             </div>

         



      

            <h2 className="major">MINOS IMPORTED FOODS</h2>
          <div className="row">
          <div className="col-6">
          <p>
              <h3><u>648 W Lake St, Addison, IL 60101</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2967.8196334805684!2d-88.00759518426096!3d41.93972696953378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fb2871efcd365%3A0xecacf8a35b060d3!2sMinos%20Imported%20Foods!5e0!3m2!1sen!2sus!4v1678317278465!5m2!1sen!2sus" width="100%" height="100%" allowfullscreen="" loading="lazy"></iframe>
              </p>
              
             </div>
             </div>
            

             <h2 className="major">DANADA DELI & PANTRY</h2>
          <div className="row">
          <div className="col-6">
          <p>
              <h3><u>1801B E Roosevelt Rd, Wheaton, IL 60187</u></h3>
              <iframe className="box alt2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2971.5455851900165!2d-88.08408418426278!3d41.85960677454971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e5421fc8020b7%3A0x620896ab75a10bb4!2sDanada%20Deli%20%26%20Pantry!5e0!3m2!1sen!2sus!4v1678317501446!5m2!1sen!2sus" width="100%" height="100%" allowfullscreen="" loading="lazy"></iframe>
              </p>
              
             </div>

            
             </div>

             

              </div>



         

</section>



  </Layout>
);

export default IndexPage;
